import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="container py-5 max-w-3xl mx-auto p-6">
            <h1 className="text-2xl font-bold mb-4">Política de Privacidade</h1>
            <p className="mb-4">Última atualização: {new Date().toLocaleDateString("pt-BR")}</p>

            <section className="mb-5">
                <h5 className="text-xl font-semibold">1. Informações Gerais</h5>
                <p>Bem-vindo ao <strong>Roulette Notify</strong> (<a href="https://roulettenotify.com.br/" className="text-blue-500">https://roulettenotify.com.br/</a>). Valorizamos sua privacidade e estamos comprometidos em proteger suas informações pessoais com total transparência e segurança.</p>
            </section>

            <section className="mb-5">
                <h5 className="text-xl font-semibold">2. Coleta de Dados</h5>
                <p>Coletamos apenas informações essenciais para garantir sua experiência no nosso serviço, incluindo dados básicos de login. Não coletamos informações desnecessárias ou sensíveis.</p>
            </section>

            <section className="mb-5">
                <h5 className="text-xl font-semibold">3. Uso dos Dados</h5>
                <p>Seus dados são utilizados exclusivamente para fornecer suporte técnico e aprimorar sua experiência dentro do nosso serviço. Garantimos que suas informações não serão utilizadas para fins comerciais não autorizados.</p>
            </section>

            <section className="mb-5">
                <h5 className="text-xl font-semibold">4. Compartilhamento de Dados</h5>
                <p>Não compartilhamos suas informações com terceiros. Seus dados são tratados com total sigilo e protegidos contra acessos não autorizados.</p>
            </section>

            <section className="mb-5">
                <h5 className="text-xl font-semibold">5. Segurança dos Dados</h5>
                <p>Seus dados estão armazenados em servidores altamente seguros e protegidos pela infraestrutura de segurança do Google, garantindo alta confiabilidade e proteção contra ameaças.</p>
            </section>

            <section className="mb-5">
                <h5 className="text-xl font-semibold">6. Direitos dos Usuários</h5>
                <p>Você tem o direito de acessar, corrigir ou solicitar a exclusão de seus dados pessoais. Para isso, entre em contato pelo e-mail <a href="mailto:suporte@roulettenotify.com.br" className="text-blue-500">suporte@roulettenotify.com.br</a>.</p>
            </section>

            <section className="mb-5">
                <h5 className="text-xl font-semibold">7. Cookies e Tecnologias de Rastreamento</h5>
                <p>Nosso site não utiliza cookies ou outras tecnologias de rastreamento para coletar informações dos usuários.</p>
            </section>

            <section className="mb-5">
                <h5 className="text-xl font-semibold">8. Idade Mínima dos Usuários</h5>
                <p>Não há restrição de idade para o uso do nosso site. No entanto, recomendamos que menores de idade utilizem o serviço sob supervisão de um responsável legal.</p>
            </section>

            <section className="mb-5">
                <h5 className="text-xl font-semibold">9. Alterações na Política</h5>
                <p>Reservamo-nos o direito de modificar esta Política de Privacidade sempre que necessário para garantir maior transparência e segurança. Os usuários serão notificados sobre qualquer alteração via e-mail.</p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;

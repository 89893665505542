/**
 * Adiciona locked e lockedTooltip 
 */
export const mapEventLocked = (event, planType) => {
    let locked = !event.allowedLevel.includes(planType)
    let lockedTooltip = ''

    if (locked) {
        let plusNeeded = event.allowedLevel.includes('PLUS')
        let premiumNeeded = event.allowedLevel.includes('PREMIUM')
        if (plusNeeded && premiumNeeded) {
            lockedTooltip = 'Disponível na versão Plus e Premium'
        } else if (plusNeeded) {
            lockedTooltip = 'Disponível na versão Plus'
        } else if (premiumNeeded) {
            lockedTooltip = 'Disponível na versão Premium'
        }
    }

    return { ...event, locked, lockedTooltip }
}

/**
 * Adiciona checked para cara variante
 */
export const mapVariantsChecked = (event, subscribedEvents) => {
    const userEvents = subscribedEvents || [] 
    const variants = event.variants.map(variant => ({ ...variant, checked: userEvents.some(item => item.variantId === variant.id ) }))
    return { ...event, variants }
}

/**
 * Atualiza uma variant para um novo estado de checked 
 */
export const mapUpdateVariant = (event, variant, checked) => {
    if (event.variants.some(v => v.id === variant.id)) {
        return { 
            ...event, 
            variants: event.variants.map(v => {
                return v.id === variant.id ? { ...variant, checked } : { ...v, checked: false}
            })
        }
    } else {
        return event
    }
}

/**
 * Ordena a lista de eventos, baseado no allowedLevel
 */
export const sortEventLevel = (a, b) => {
    const order = ['FREE', 'PLUS', 'PREMIUM'];

    // Transformamos os arrays em listas de níveis normalizados (removendo espaços e separando corretamente)
    const aLevels = a.allowedLevel.flatMap(l => l.split(',').map(s => s.trim()));
    const bLevels = b.allowedLevel.flatMap(l => l.split(',').map(s => s.trim()));

    // Encontramos o menor índice da ordem para cada conjunto de níveis
    const minA = Math.min(...aLevels.map(l => order.indexOf(l)).filter(i => i !== -1));
    const minB = Math.min(...bLevels.map(l => order.indexOf(l)).filter(i => i !== -1));

    return minA - minB;
} 
import './../../components/pages/PublicHome.css'

import { useNavigate } from "react-router-dom"
import { Header } from "../../components/Header"
import { Hero } from "./sections/Hero"
import { useContext, useEffect } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Faq } from "./sections/Faq";
import { Footer } from "./sections/Footer";
import { Testimonials } from "./sections/Testimonials";
import { Plans } from "./sections/Plans";
import { HowItWorks } from "./sections/HowItWorks";
import { Telegram } from "./sections/Telegram";
import { logEvent } from 'firebase/analytics';
import { analytics } from '../../firebase';

export const Home = () => {

    const { user } = useContext(AuthContext);
    const navigate = useNavigate()

    useEffect(_ => {
        logEvent(analytics, 'page_view', { page: 'Home' })
    }, [])

    function onClickCreateAccount() {
        if (user) {
            navigate("/dashboard/home");
        } else {
            navigate("/login");
        }
    }

    return (
        <>
            <div style={{
                backgroundImage: `url('/images/home_header_bg.jpg')`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                color: "white"
            }}>
                <Header clickCreateAccount={onClickCreateAccount} />
                <Hero clickCreateAccount={onClickCreateAccount} />
            </div>

            <HowItWorks />
            <Telegram clickCreateAccount={onClickCreateAccount} />
            <Plans />
            <Testimonials />
            <Faq />
            <Footer />
        </>
    )
}
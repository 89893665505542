import './RuleField.css'
import { ArrowForwardRounded, DeleteOutlineOutlined } from "@mui/icons-material"
import { Col, Form, Row } from "react-bootstrap"

export const RuleField = ({
    rule,
    onChangeFrom,
    onClickTrackPadTo,
    onClickDeleteRule,
    disableDelete = false
}) => (
    <Row className='mb-1 align-items-center'>
        <Col>
            <Form.Group className='flex-fill'>
                <Form.Control
                    onChange={e => onChangeFrom(e.target.value)}
                    value={rule.from.value}
                    type="number"
                    placeholder="Número gatilho"
                    isInvalid={rule.from.error}
                    maxLength={2} />

                <Form.Control.Feedback>{rule.from.error}</Form.Control.Feedback>
            </Form.Group>
        </Col>
        <Col xs="auto" className='p-0 px-lg-2'>
            <div className='d-flex align-items-center gap-2'>
                <span className='d-none d-lg-block small'>Para</span>
                <ArrowForwardRounded />
            </div>
        </Col>
        <Col className='pe-1'>
            <Form.Group className='flex-fill'>
                <Form.Control
                    readOnly
                    value={rule.to.value}
                    onClick={onClickTrackPadTo}
                    placeholder="Número alvos"
                    isInvalid={rule.to.error} />
            </Form.Group>
        </Col>
        <Col xs="auto" className='p-0'>
            <button type='button' className='btn-delete-rule' onClick={onClickDeleteRule} disabled={disableDelete}>
                <DeleteOutlineOutlined />
            </button>
        </Col>
    </Row>
)
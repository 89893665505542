import { format, isToday, isYesterday } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Card } from "react-bootstrap";
import { EmptyData } from "../../../../components/EmptyData";
import { State } from "../../../../components/State";
import { useDashboard } from "../../../../contexts/DashboardContext";

const mapAlertName = (alert) => {
    if (alert?.type === 'EVENT') {
        return alert.name
    }

    if (alert.pattern && !alert.pattern.startsWith('Padrão')) {
        return `Padrão ${alert.pattern}`
    }

    return alert.pattern
} 

export const AlertsTable = ({ alerts }) => {

    if (!alerts || alerts.length <= 0) {
        return <></>
    }

    const formatDate = (timestamp) => {
        const date = timestamp.toDate()

        if (isToday(date)) {
            return `Hoje às ${format(date, "HH:mm")}`
        }

        if (isYesterday(date)) {
            return `Ontem às ${format(date, "HH:mm")}`
        }

        return format(date, "dd/MM/yyyy 'às' HH:mm", { locale: ptBR });
    }

    return (
        <div className="table-responsive">
            <table>
                <thead>
                    <tr>
                        <th>Nome</th>
                        <th>Roleta</th>
                        <th>Histórico</th>
                        <th>Data</th>
                    </tr>
                </thead>
                <tbody>
                    {alerts.map(alert => (
                        <tr key={alert.id}>
                            <td>{mapAlertName(alert)}</td>
                            <td>{alert.roulette}</td>
                            <td>{alert.history}</td>
                            <td>{formatDate(alert.datetime)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export const AlertsSection = ({ onClickCreatePattern }) => {

    const dashboard = useDashboard()

    const hasPatterns = dashboard.patterns.length > 0
    const hasAlerts = dashboard.alerts.length > 0

    return (
        <>
            <Card className="mb-3 p-0 overflow-hidden">

                <div className="px-4 py-3">
                    <h5 className="mb-1">Histórico de alertas</h5>
                    <State visible={dashboard.todayAlerts.length > 0}> 
                        <span className="text-secondary">
                            <State visible={dashboard.todayAlerts.length === 1}>
                                1 alerta recebido hoje
                            </State>

                            <State visible={dashboard.todayAlerts.length > 1}>
                                {dashboard.todayAlerts.length} alertas recebidos hoje
                            </State>
                        </span>
                    </State>
                </div>

                <AlertsTable alerts={dashboard.alerts.slice(0, 10)} />

                <State visible={(hasPatterns && !hasAlerts)}>
                    <EmptyData
                        title="Nenhum alerta enviado"
                        subtitle="Você receberá alertas sempre que os padrões criados forem detectados" />
                </State>

                <State visible={(!hasPatterns && !hasAlerts)}>
                    <EmptyData
                        title="Nenhum alerta enviado"
                        subtitle="Crie seu primeiro padrão para começar a receber alertas"
                        button="Criar meu primeiro padrão"
                        buttonClick={onClickCreatePattern} />
                </State>

            </Card>
        </>
    )
}
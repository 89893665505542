import { Equalizer, Group, Lock, Repeat } from "@mui/icons-material"
import { Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap"
import { Radar } from "../../../components/Radar"
import { State } from "../../../components/State"

export const FollowedEvent = ({ event, className = '' }) => {
    return (
        <Card className={className}>
            <Row>
                <Col>
                    <div className="d-flex align-items-center gap-3 mb-2">
                        <State visible={event.locked}>
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{event.lockedTooltip}</Tooltip>}>
                                <div className="d-flex align-items-center justify-content-center rounded-circle p-1" style={{ backgroundColor: 'rgba(235, 87, 87, 0.1)' }}>
                                    <Lock style={{ color: '#EB5757', fontSize: 20 }} />
                                </div>
                            </OverlayTrigger>
                        </State>

                        <State visible={!event.locked}>
                            <Radar />
                        </State>

                        <h5 className="m-0 text-dark" style={{ fontSize: 16 }}>{event.name}</h5>
                    </div>

                    <State visible={event.description}>
                        <p className="m-0 text-secondary" style={{ fontSize: 14 }}>{event.description}</p>
                    </State>

                    <Row className="g-2 gx-3 mt-1">
                        <Col md="auto" className="d-flex align-items-center gap-2 text-dark" style={{ fontSize: 14 }}>
                            <Repeat style={{ fontSize: 20 }} />{event.variant.count} Repetições
                        </Col>

                        <State visible={event.alerts}>
                            <Col md="auto" className="d-flex align-items-center gap-2 text-dark" style={{ fontSize: 14 }}>
                                <Equalizer style={{ fontSize: 20 }} />{event.alerts} Alertas hoje
                            </Col>
                        </State>

                        <Col md="auto" className="d-flex align-items-center gap-2 text-dark" style={{ fontSize: 14 }}>
                            <Group style={{ fontSize: 20 }} /> {event.variant.following} Acompanhando
                        </Col>
                    </Row>
                </Col>
                {/* <Col xs='auto'>
                    <button className="btn-icon">
                        <MoreVert />
                    </button>
                </Col> */}
            </Row>
        </Card>
    )
}
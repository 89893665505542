import { useEffect } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap"
import { useAuth } from '../../../contexts/AuthContext'
import { Api } from '../../../service/Api'
import './WelcomeModal.css'

const WelcomeModal = ({ show = false, onHide }) => {

    const { user } = useAuth()

    useEffect(() => {
        if (user) {
            Api.updateUser(user.uid, { welcomeDisplayed: true });
        }
    }, [user]);

    return (
        <Modal className="welcome-modal" show={show} onHide={onHide} centered>
            <ModalHeader className="border-0" closeButton={true} />
            <ModalBody className="text-center">
                <img className="mb-3" src="/images/logo100.png" alt="Logo" width={100} />
                <h5>🚀 Bem-vindo ao Roulette Notify</h5>
                <p className='subtitle'>Aqui você pode configurar suas estratégias, monitorar eventos como repetições de números e cores, e receber notificações no Telegram — tudo em tempo real!</p>
            </ModalBody>
            <ModalFooter className="border-0">
                <button className='btn btn-link text-center w-100' onClick={onHide}>Começar</button>
            </ModalFooter>
        </Modal>
    )
}

export default WelcomeModal
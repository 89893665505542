import { CheckBoxOutlineBlankRounded, CheckBoxRounded, Lock, RepeatRounded } from "@mui/icons-material"
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap"
import { State } from "../../../components/State"

export const FollowEventForm = ({ event, onChange, className = '' }) => {

    const repeatIconColor = !event.locked && event.variants.some(v => v.checked) ? '#3C84E7' : '#AAA'

    return (
        <Card className={className}>
            <div className="d-flex align-items-center gap-2">
                <State visible={event.locked}>
                    <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{event.lockedTooltip}</Tooltip>}>
                        <div className="d-flex align-items-center justify-content-center rounded-circle p-1" style={{ backgroundColor: 'rgba(235, 87, 87, 0.1)' }}>
                            <Lock style={{ color: '#EB5757', fontSize: 20 }} />
                        </div>
                    </OverlayTrigger>
                </State>
                <RepeatRounded  style={{ fontSize: 20, color: repeatIconColor }} />
                <h6 className="h5 m-0" style={{ fontSize: 16 }}>{event.name}</h6>
            </div>
            <State visible={!event.locked}>
                <div className="d-flex flex-wrap gap-3 mt-3">
                    {event.variants.map(variant => (
                        <CheckBox
                            key={variant.id}
                            onChange={checked => onChange(variant, checked)}
                            checked={variant.checked}>{variant.count} Repetições</CheckBox>
                    ))}
                </div>
            </State>
        </Card>
    )
}

const CheckBox = ({ checked = false, onChange = () => { }, children }) => {
    return (
        <div className="d-flex align-items-center gap-1 c-pointer" onClick={_ => onChange(!checked)}>
            {checked ? (
                <CheckBoxRounded style={{ color: '#3C84E7' }} />
            ) : (
                <CheckBoxOutlineBlankRounded />
            )}
            {children}
        </div>
    )
}
import axios from 'axios';
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { addDoc, arrayRemove, arrayUnion, collection, deleteDoc, doc, getDoc, getDocs, increment, onSnapshot, orderBy, query, serverTimestamp, setDoc, updateDoc, where, writeBatch } from 'firebase/firestore';
import { auth, firestore } from "../firebase";

axios.defaults.baseURL = process.env.REACT_APP_API_URL
axios.interceptors.request.use(async config => {
    const token = await auth.currentUser.getIdToken()
    if (token) config.headers.Authorization = `Bearer ${token}`
    return config
}, error => {
    return Promise.reject(error);
})

export const loginGoogle = async () => new Promise(async (resolve, reject) => {
    const whiteLoginErrors = [
        'auth/popup-closed-by-user',
        'auth/cancelled-popup-request'
    ]

    try {
        const provider = new GoogleAuthProvider()
        let userCredential = await signInWithPopup(auth, provider)
        let firebaseUser = userCredential.user
        await ensureUserDocument(firebaseUser)
        resolve({ type: 'success' })
    } catch (error) {
        if (whiteLoginErrors.includes(error.code)) {
            resolve({ type: 'cancelled' })
        } else {
            reject(error)
        }
    }
})

export const fetchPlans = async () => {
    const querySnapshot = await getDocs(collection(firestore, "/plans"))
    return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
}

export const fetchPatterns = async (userId) => {
    const patternsRef = collection(firestore, `patterns`);
    const q = query(patternsRef, where('userUid', '==', userId));
    const querySnapshot = await getDocs(q);
    const list = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    list.sort((a, b) => (a.active === b.active) ? 0 : a.active ? -1 : 1)
    return list
}

export const fetchRoulettes = async () => {
    const querySnapshot = await getDocs(collection(firestore, "/roulettes"))
    const items = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
    items.sort((a, b) => (a.name < b.name) ? -1 : (a.name > b.name) ? 1 : 0)
    return items
}

export const savePattern = async (userUid, pattern) => {
    if (pattern.id === undefined) {
        delete pattern.id
    }

    if (pattern.id) {
        const patternRef = doc(firestore, 'patterns', pattern.id)
        return await setDoc(patternRef, { userUid, ...pattern })
    }

    const collectionRef = collection(firestore, 'patterns')
    return await addDoc(collectionRef, { userUid, ...pattern })
}

export const deletePattern = async (patternId) => {
    const docRef = doc(firestore, `patterns`, patternId)
    return await deleteDoc(docRef);
}

export const saveTelegramCode = async (userId, telegramCode) => {
    const data = { chatId: telegramCode }
    const docRef = doc(firestore, "users/", userId);
    return await updateDoc(docRef, data).then(_ => data)
}

export const createPreference = async (planId) => {
    return axios.post('preferences/create', ({ planId }))
}

export const createSubscription = async (planId) => {
    return axios.post('subscriptions/create', ({ planId }))
}

export const createUserPlan = async (planId) => {
    return axios.post('subscriptions/create/plan', ({ planId }))
}

export const fetchSubscription = async (preApprovalId) => {
    return axios.get(`subscriptions/${preApprovalId}`)
}

export const fetchEvents = async () => {
    const eventsCollection = collection(firestore, "events")
    const eventsSnapshot = await getDocs(eventsCollection)

    return await Promise.all(eventsSnapshot.docs.map(async (doc) => {
        const variantsCollection = collection(doc.ref, "variants")
        const variantsSnapshot = await getDocs(variantsCollection)
        const variants = variantsSnapshot.docs.map(variantDoc => ({ id: variantDoc.id, ...variantDoc.data() }))
        variants.sort((a, b) => a.count - b.count)
        return { id: doc.id, ...doc.data(), variants: variants }
    }))
}

export const fetchFollowedEvents = async (userId) => {
    return []
}

export const fetchTrendingEvents = async () => {
    return []
}

export const cancelSubscription = async () => {
    return axios.post(`subscriptions/cancel`)
}

/**
 * Create (if needed) and retrieve the user document
 */
export const ensureUserDocument = async (firebaseUser) => {

    const userId = firebaseUser.uid
    const userRef = doc(firestore, 'users', userId)
    const userDoc = await getDoc(userRef)

    if (!userDoc.exists()) {

        const newUserDocument = {
            uid: userId,
            name: firebaseUser.displayName,
            email: firebaseUser.email,
            photoURL: firebaseUser.photoURL,
            phoneNumber: firebaseUser.phoneNumber,
            welcomeDisplayed: false,
            starterStepsClosed: false,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp(),
            status: 'ACTIVE',
            patterns: [],
            plan: {
                type: "FREE",
                activationDate: serverTimestamp(),
                expirationDate: null,
                paidAmount: 0.0
            }
        }

        await setDoc(userRef, newUserDocument)
        return newUserDocument
    }

    return userDoc.data()
}

const observeAlerts = (userUid, callback) => {

    const q = query(
        collection(firestore, "alerts"),
        where("userUid", "==", userUid),
        orderBy("datetime", "desc")
    )

    return onSnapshot(q, (querySnapshot) => {
        const alerts = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        callback(alerts)
    })
}

const observePatterns = (userUid, callback) => {
    const patternsRef = collection(firestore, `patterns`);
    const q = query(patternsRef, where('userUid', '==', userUid));
    return onSnapshot(q, (querySnapshot) => {
        const patterns = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        callback(patterns)
    })
}

const observeUser = (userUid, callback) => {
    const userRef = doc(firestore, 'users', userUid)
    return onSnapshot(userRef, (querySnapshot) => callback(querySnapshot.data()))
}

const updateUser = async (userUid, userDoc) => {
    const userRef = doc(firestore, 'users', userUid)
    return await updateDoc(userRef, userDoc)
}

const updateUserSubscribedEvents = async (userId, newSubscribedEvents = []) => {
    try {
        if (!userId) {
            throw Error("Usuário inválido.")
        }

        const userRef = doc(firestore, "users", userId);
        const userSnap = await getDoc(userRef);
        
        if (!userSnap.exists()) {
            throw Error("Usuário não encontrado.")
        }

        const userData = userSnap.data();
        const oldSubscriptions = userData.subscribedEvents || [];

        // Converte listas para Map para facilitar comparação
        const oldSubscriptionsMap = new Map(oldSubscriptions.map(event => [`${event.eventId}-${event.variantId}`, event]));
        const newSubscriptionsMap = new Map(newSubscribedEvents.map(event => [`${event.eventId}-${event.variantId}`, event]));

        const removedSubscriptions = oldSubscriptions.filter(({ eventId, variantId }) =>
            !newSubscriptionsMap.has(`${eventId}-${variantId}`)
        );

        const addedSubscriptions = newSubscribedEvents.filter(({ eventId, variantId }) =>
            !oldSubscriptionsMap.has(`${eventId}-${variantId}`)
        );

        const batch = writeBatch(firestore);

        // Atualiza a lista de eventos inscritos do usuário
        batch.update(userRef, { subscribedEvents: newSubscribedEvents });

        // Processa eventos adicionados
        addedSubscriptions.forEach(({ eventId, variantId }) => {
            const variantRef = doc(firestore, `events/${eventId}/variants`, variantId);
            batch.update(variantRef, {
                following: increment(1),
                followers: arrayUnion(userId)
            });
        });

        // Processa eventos removidos
        removedSubscriptions.forEach(({ eventId, variantId }) => {
            const variantRef = doc(firestore, `events/${eventId}/variants`, variantId);
            batch.update(variantRef, {
                following: increment(-1),
                followers: arrayRemove(userId)
            });
        });

        await batch.commit();
        console.log("Batch update realizado com sucesso!");

    } catch (error) {
        console.error("Erro ao atualizar os eventos:", error);
    }
}

const minusVariantFollowing = async (eventId, variantId) => {
    const variantRef = doc(firestore, 'events', eventId, 'variants', variantId)
    const variantDoc = await getDoc(variantRef)
    const variant = variantDoc.data()
    return await updateDoc(variantRef, { following: (variant.following - 1) })
}

const plusVariantFollowing = async (eventId, variantId) => {
    const variantRef = doc(firestore, 'events', eventId, 'variants', variantId)
    const variantDoc = await getDoc(variantRef)
    const variant = variantDoc.data()
    return await updateDoc(variantRef, { following: (variant.following + 1) })
}

export const Api = {
    fetchPlans,
    fetchPatterns,
    fetchRoulettes,
    fetchSubscription,
    fetchEvents,
    fetchFollowedEvents,
    fetchTrendingEvents,
    savePattern,
    deletePattern,
    observeAlerts,
    observePatterns,
    observeUser,
    updateUser,
    createSubscription,
    cancelSubscription,
    plusVariantFollowing,
    minusVariantFollowing,
    updateUserSubscribedEvents
}
import { NotificationsPaused } from "@mui/icons-material"
import { useAuth } from "../../../../contexts/AuthContext"
import AlertInformation from "../../../../components/AlertInformation"

export const PreferenceAlert = () => {

    const { user } = useAuth()
    const notificationsPaused = user?.preferences?.notificationsPaused

    if (!notificationsPaused) {
        return <></>
    }

    return (
        <AlertInformation
            icon={<NotificationsPaused />}
            title="Notificações desativadas"
            linkText="Ativar notificações"
            link="/dashboard/account/preferences"
            variant="danger"
            className="mb-3">
            <div>
                <p className="mb-0">Você não receberá novos alertas enquanto estiver com as notificações desativadas.</p>
            </div>
        </AlertInformation>

    )
}
import './AlertInformation.css'
import { Link } from 'react-router-dom'
import { Card } from "react-bootstrap"
import { State } from './State'

const AlertInformation = ({ title = "", subtitle = "", linkText = "", link = "", className = "", variant = 'warning', icon = null, children }) => {
    return (
        <Card className={`AlertInformation ${variant} ${className}`}>
            {icon && (
                <div className='icon'>
                    {icon}
                </div>
            )}
            <div className='alert-content'>
                <h5 className='title mb-2'>{title}</h5>

                <State visible={subtitle}>
                    <p className="subtitle mb-2">{subtitle}</p>
                </State>

                <div className='mb-2'>
                    {children}
                </div>

                <State visible={link && linkText}>
                    <div className='d-inline-block text-gradient'>
                        <Link to={link}><strong>{linkText}</strong></Link>
                    </div>
                </State>
            </div>
        </Card>
    )
}

export default AlertInformation
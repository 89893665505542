import { Button, Container } from "react-bootstrap"

export const Hero = ({ clickCreateAccount }) => {
    return (
        <section>
            <Container className="d-flex align-items-center justify-content-center" style={{ minHeight: 500 }}>
                <div className="text-center" style={{ maxWidth: 850 }}>
                    <h2 className="mb-4 fw-bold display-5">Crie padrões e receba alertas em tempo real no Telegram.</h2>
                    <p className="mx-auto mb-4" style={{ maxWidth: 460 }}>Configure padrões únicos e receba notificações instantâneas. Ganhe controle total e maximize suas estratégias.</p>
                    <Button className="shadow-none" onClick={clickCreateAccount}>Criar conta grátis</Button>
                </div>
            </Container>
        </section>
    )
}
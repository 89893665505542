import React, { useCallback, useContext } from "react";
import AuthProvider, { AuthContext } from "./contexts/AuthContext";
import { BrowserRouter, Navigate, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Home } from "./pages/home/Home";
import { Login } from "./pages/login/Login";
import { Dashboard } from "./pages/dashboard/Dashboard";
import { DashboardHome } from "./pages/dashboard/home/Home";
import { Patterns } from "./pages/dashboard/patterns/Patterns";
import { Alerts } from "./pages/dashboard/alerts/Alerts";
import { Account } from "./pages/dashboard/account/Account";
import { Settings } from "./pages/dashboard/settings/Settings";
import { PatternForm } from "./pages/dashboard/patterns/PatternForm";
import { ToastProvider } from "./contexts/ToastContext";
import { DashboardProvider } from "./contexts/DashboardContext";
import { Plans } from "./pages/dashboard/plans/Plans";
import { Telegram } from "./pages/dashboard/telegram/Telegram";
import { Logout } from "./pages/logout/Logout";
import { NotFound } from "./pages/notfound/NotFound";
import { CheckoutConcluded } from "./pages/dashboard/plans/CheckoutConcluded";
import { PlansProvider } from "./contexts/PlansContext";
import { Preferences } from "./pages/dashboard/preferences/Preferences";
import { useEffect } from "react";
import PrivacyPolicy from "./pages/privacy/PrivacyPolicy";
import { EventsHome } from "./pages/dashboard/events/EventsHome";
import { EventsFollow } from "./pages/dashboard/events/EventsFollow";
import { Maintenance } from "./pages/maintenance/Maintenance";

const checkIsUserLogged = () => {
  return localStorage.getItem('logged') === 'true'
}

function LoadingState() {
  return (
    <div className="w-100 h-100 flex-center">
      <span className="text-muted">Carregando...</span>
    </div>
  )
}

function LoginWrapper() {

  const { loading } = useContext(AuthContext)
  const location = useLocation()
  const navigate = useNavigate()

  const navigateDashboard = useCallback(() => {
    const redirectPath = location.state?.from?.pathname || '/dashboard/home';
    const currentSearch = location.state?.from?.search || '';
    navigate(`${redirectPath}${currentSearch}`, { replace: true });
  }, [location.state?.from?.pathname, location.state?.from?.search, navigate])

  useEffect(_ => {
    if (checkIsUserLogged()) {
      navigateDashboard()
    }
  }, [navigateDashboard])

  if (loading) {
    return <LoadingState />
  }

  return <Login navigateDashboard={navigateDashboard} />
}

function DashboardWrapper() {

  const { user } = useContext(AuthContext)
  const navigate = useNavigate()

  useEffect(_ => {
    if (!checkIsUserLogged()) {
      navigate('/login')
    }
  }, [navigate])

  if (!user) {
    return <LoadingState />
  }

  return (
    // <DashboardProvider>
    //   <Dashboard />
    // </DashboardProvider>
    <Maintenance />
  )
}

function App() {
  return (
    <PlansProvider>
      <ToastProvider>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="" element={<Home />} />
              <Route path="login" element={<LoginWrapper />} />
              <Route path="logout" element={<Logout />} />
              <Route path="privacy-policy" element={<PrivacyPolicy />} />
              <Route path="dashboard" element={<DashboardWrapper />}>
                <Route path="" element={<Navigate to="/dashboard/home" />} />
                <Route path="home" element={<DashboardHome />} />
                <Route path="patterns" element={<Patterns />} />
                <Route path="patterns/new" element={<PatternForm />} />
                <Route path="patterns/edit/:id" element={<PatternForm isEdit={true} />} />
                <Route path="alerts" element={<Alerts />} />
                <Route path="account" element={<Account />} />
                <Route path="account/preferences" element={<Preferences />} />
                <Route path="plans" element={<Plans />} />
                <Route path="plans/checkout/concluded" element={<CheckoutConcluded />} />
                <Route path="telegram" element={<Telegram />} />
                <Route path="settings" element={<Settings />} />
                <Route path="events" element={<EventsHome />} />
                <Route path="events/follow" element={<EventsFollow />} />
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ToastProvider>
    </PlansProvider>
  );
}

export default App;

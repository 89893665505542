import { HourglassTopRounded } from "@mui/icons-material"
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom"
import { useAuth } from "../../contexts/AuthContext";
import { serverTimestamp } from "firebase/firestore";
import { Api } from "../../service/Api";

export const Maintenance = () => {

    const { user } = useAuth()
    const hasRunLastAccess = useRef(false)

    useEffect(_ => {
        if (!hasRunLastAccess.current && user) {
            Api.updateUser(user.uid, { lastAccess: serverTimestamp() })
            hasRunLastAccess.current = true
        }
    }, [user])

    return (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center flex-column gap-1">
            <HourglassTopRounded />
            <h5 className="m-0 mt-2">Servidor em Manutenção</h5>
            <p className="m-0">Você poderá acessar nossos serviços em breve</p>
            <Link to={'/'}>Voltar ao Início</Link>
        </div>
    )
}
import { Button, Card, Modal, Spinner } from "react-bootstrap"
import { EmptyData } from "../../../components/EmptyData"
import { useNavigate } from "react-router-dom"
import { State } from "../../../components/State";
import { useDashboard } from "../../../contexts/DashboardContext";
import { StatusDot } from "../../../components/StatusDot";
import { Delete, Edit } from "@mui/icons-material";
import { OptionButton } from "./components/OptionButton";
import { useEffect, useState } from "react";
import { Api } from "../../../service/Api";
import { useToast } from "../../../contexts/ToastContext";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../firebase";

export const Patterns = () => {

    const navigate = useNavigate()
    const dashboard = useDashboard()
    const toast = useToast()

    const [deleteDialog, setDeleteDialog] = useState({ pattern: null, open: false, loading: false })

    useEffect(_ => {
        logEvent(analytics, 'page_view', { page: 'Dash-Patterns' })
    }, [])

    function goCreatePattern() {
        navigate('/dashboard/patterns/new')
    }

    function onClickDeletePattern(pattern) {
        if (pattern) setDeleteDialog({ pattern, open: true, loading: false })
    }

    function onClickEditPattern(pattern) {
        if (pattern) navigate(`/dashboard/patterns/edit/${pattern.id}`)
    }

    function onClickDeleteConfirmDialog() {
        setDeleteDialog(state => ({ ...state, loading: true }))
        Api.deletePattern(deleteDialog.pattern.id)
            .catch(_ => toast.error('Desculpe, ocorreu um erro!'))
            .finally(_ => onHideDeleteDialog())
    }

    function onHideDeleteDialog() {
        if (!deleteDialog.loading) {
            setDeleteDialog({ pattern: null, open: false, loading: false })
        }
    }

    return (
        <>
            <Card className="mb-4 p-0 overflow-hidden">

                <div className="d-flex flex-lg-row flex-column gap-3 justify-content-between p-4">
                    <h5>Meus Padrões</h5>
                    <div>
                        <Button onClick={goCreatePattern} className="w-100 w-lg" variant="primary">Adicionar padrão</Button>
                    </div>
                </div>

                <State visible={dashboard.patterns.length > 0}>
                    <div className="table-responsive">
                        <table>
                            <thead>
                                <tr>
                                    <th>Nome</th>
                                    <th>Status</th>
                                    <th>Regras</th>
                                    <th>Ações</th>
                                </tr>
                            </thead>
                            <tbody>
                                {dashboard.patterns.map(pattern => (
                                    <tr key={pattern.id}>
                                        <td>{pattern.name}</td>
                                        <td>
                                            <StatusDot
                                                variant={pattern.active ? 'success' : 'danger'}
                                                label={pattern.active ? 'Ativo' : 'Inativo'} />
                                        </td>
                                        <td>{pattern.rules.length}</td>
                                        <td>
                                            <div className="d-flex gap-1">
                                                <OptionButton onClick={_ => onClickEditPattern(pattern)} variant={'blue'}><Edit /></OptionButton>
                                                <OptionButton onClick={_ => onClickDeletePattern(pattern)} variant={'red'}><Delete /></OptionButton>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </State>

                <State visible={(dashboard.patterns.length === 0)}>
                    <EmptyData
                        title="Nenhum padrão criado"
                        subtitle="Crie seu primeiro padrão para começar a receber alertas" />
                </State>

            </Card>

            <Modal
                backdrop={deleteDialog.loading ? 'static' : 'true'}
                show={deleteDialog.open}
                onHide={() => onHideDeleteDialog()}>
                <Modal.Header closeButton={!deleteDialog.loading}>
                    <h5>Importante</h5>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Deseja realmente excluir o padrão <span className="fw-bold">{deleteDialog.pattern && deleteDialog.pattern.name}</span>
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={onHideDeleteDialog} disabled={deleteDialog.loading}>Cancelar</Button>
                    <Button variant="danger" onClick={onClickDeleteConfirmDialog} disabled={deleteDialog.loading}>
                        <State visible={deleteDialog.loading}><Spinner size="sm" /> Excluindo</State>
                        <State visible={!deleteDialog.loading}>Excluir</State>
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
import { AddRounded, BlockRounded, HelpOutlineRounded } from '@mui/icons-material'
import { useState } from 'react'
import { Button, Card, Form, Spinner } from "react-bootstrap"
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../contexts/AuthContext'
import { useDashboard } from '../../../contexts/DashboardContext'
import { usePlans } from '../../../contexts/PlansContext'
import { useToast } from '../../../contexts/ToastContext'
import { Api } from '../../../service/Api'
import { RuleField } from './components/RuleField'
import { NumberPickerDialog } from './NumberPickerDialog'
import { PatternInfoDialog } from './PatternInfoDialog'
import { usePatternForm } from './utils/PatternFormHook'
import AlertInformation from '../../../components/AlertInformation'
import { State } from '../../../components/State'

const MIN_RULE_COUNT = 1

export const PatternForm = ({ isEdit = false }) => {

    const { id } = useParams()
    const { user } = useAuth()
    const { patterns } = useDashboard()
    const { plans } = usePlans()

    const toast = useToast()
    const form = usePatternForm(id)
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [rulePickerItem, setRulePickerItem] = useState(null)
    const [numberPickerVisible, setNumberPickerVisible] = useState(false)
    const toggleNumberPickerVisible = () => setNumberPickerVisible(!numberPickerVisible)

    const patternLimit = plans.find(el => el.type === user.plan.type).patternLimit
    const isPatternLimitReached = !isEdit && patterns.length >= patternLimit

    const [infoDialogVisible, setInfoDialogVisible] = useState(
        !user?.patternInfoDisplayed &&
        !isPatternLimitReached
    )

    function onChangeRuleFrom(text, index) {
        form.setNumberFrom(index, text)
    }

    function onClickAddRule() {
        form.addRule()
    }

    function onClickDeleteRule(index) {
        form.deleteRule(index)
    }

    function onClickTrackPadTo(ruleIndex) {
        let rule = form.formData.rules[ruleIndex]
        setRulePickerItem({ index: ruleIndex, numbers: rule.to.value })
        toggleNumberPickerVisible()
    }

    function onConfirmTrackPadNumbers(numbers) {
        let ruleIndex = rulePickerItem.index
        form.setNumbersTo(ruleIndex, numbers)
        toggleNumberPickerVisible()
        setRulePickerItem(null)
    }

    function handleChangeName(event) {
        form.setName(event.target.value)
    }

    function handleChangeNotifyAfter(event) {
        form.setNotifyCount(event.target.value)
    }

    function handleChangeRouletteTolerance(event) {
        form.setRoundTolerance(event.target.value)
    }

    function handleChangeActive() {
        form.toggleActive()
    }

    function onSubmitForm() {
        form.validateForm()
        if (form.formData.isValid) {
            addPattern(form.getAsObject())
        } else {
            toast.error('Preencha todos os campos corretamente', 'form-error')
        }
    }

    function addPattern(pattern) {
        setLoading(true)
        Api.savePattern(user.uid, pattern)
            .then(_ => {
                navigate(-1)
                toast.success('Padrão criado com sucesso')
            })
            .catch(e => {
                toast.error('Desculpe, ocorreu um erro')
            })
            .finally(_ => setLoading(false))
    }

    if (isPatternLimitReached) {
        return (
            <AlertInformation
                icon={<BlockRounded />}
                title="Limite de padrões atingido!"
                variant='danger'
                link='/dashboard/plans'
                linkText='Saiba mais sobre nossos planos'>
                <p className="m-0">Você atingiu o limite de padrões para o seu plano atual.</p>

                <State visible={user.plan.type !== 'PREMIUM'}>
                    <p className="m-0">Considere fazer um upgrade de plano para criar novos padrões.</p>
                </State>

            </AlertInformation>
        )
    }

    return (
        <Card>
            <div className='d-flex align-items-center justify-content-between'>
                <h5>{isEdit ? 'Editar padrão' : 'Criar Padrão'}</h5>
                <button className='btn-icon' onClick={_ => setInfoDialogVisible(true)}>
                    <HelpOutlineRounded className='text-dark' />
                </button>
            </div>

            <Form onSubmit={onSubmitForm} className="mt-4">

                <Form.Group>
                    <Form.Label className='fw-bold'>Nome do padrão</Form.Label>
                    <Form.Control
                        value={form.formData.name.value}
                        onChange={handleChangeName}
                        type="text"
                        placeholder="Digite aqui"
                        isInvalid={form.formData.name.error} />
                </Form.Group>

                <Form.Label className='fw-bold mt-4'>Regras</Form.Label>
                {form.formData.rules.map((rule, index) => {
                    return (
                        <RuleField
                            onClickDeleteRule={_ => onClickDeleteRule(index)}
                            onChangeFrom={text => onChangeRuleFrom(text, index)}
                            onClickTrackPadTo={_ => onClickTrackPadTo(index)}
                            disableDelete={form.formData.rules.length === MIN_RULE_COUNT}
                            rule={rule}
                            key={`rule-field=${index}`}
                        />
                    )
                })}

                <button type='button' className='btn btn-light mt-1 border' onClick={onClickAddRule}>
                    Adicionar regra <AddRounded />
                </button>

                <Form.Group className='mt-4'>
                    <Form.Label className='fw-bold'>Quanto notificar?</Form.Label>
                    <Form.Select value={form.formData.notifyAfter.value} onChange={handleChangeNotifyAfter}>
                        {form.formData.rules.map((_, index) => {
                            let value = index + 1

                            if (value === 1) {
                                return <option key={value} value={value}>Após {value} regra</option>
                            }

                            return <option key={value} value={value}>Após {value} regras</option>
                        })}
                    </Form.Select>
                    <Form.Text className='d-block mt-2'>Informe o número mínimo de regras que devem ser atendidas dentro deste padrão para que a notificação seja enviada. <br /> Por exemplo, se você configurar 2 regras, o sistema notificará apenas quando pelo menos duas regras forem cumpridas.</Form.Text>
                </Form.Group>

                <Form.Group className='mt-4'>
                    <Form.Label className='fw-bold'>Tolerância de cada regra</Form.Label>
                    <Form.Select value={form.formData.roundTolerance.value} onChange={handleChangeRouletteTolerance}>
                        <option value="1">1 rodada</option>
                        <option value="2">2 rodadas</option>
                    </Form.Select>
                    <Form.Text className='d-block mt-2'>Até quantas rodadas seguintes podem ser consideradas para validar a regra. <br /> Por exemplo, se configurado como 2, o sistema analisará os dois próximos resultados após o número gatilho para verificar se a regra foi validada.</Form.Text>
                </Form.Group>

                <Form.Check
                    onChange={handleChangeActive}
                    checked={form.formData.active}
                    className='mt-4 mb-4'
                    type="switch"
                    id="custom-switch"
                    label="Ativo" />

                <div className='row'>
                    <div className='col-12 offset-lg-9 col-lg-3'>
                        <Button variant='primary' className='w-100' disabled={loading} onClick={onSubmitForm}>
                            {loading && <Spinner size='sm' />}
                            <span className="px-2">{!loading && (isEdit) ? 'Salvar mudanças' : 'Criar padrão'}</span>
                        </Button>
                    </div>
                </div>
            </Form>

            <PatternInfoDialog
                show={infoDialogVisible}
                onHide={_ => setInfoDialogVisible(false)} />

            <NumberPickerDialog
                show={numberPickerVisible}
                ruleNumbers={rulePickerItem ? rulePickerItem.numbers : []}
                toggle={toggleNumberPickerVisible}
                onCancel={_ => setNumberPickerVisible(false)}
                onConfirm={list => onConfirmTrackPadNumbers(list)} />

        </Card>
    )
}
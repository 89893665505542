import { Modal } from "react-bootstrap"
import { Api } from "../../../service/Api"
import { useAuth } from "../../../contexts/AuthContext"

export const PatternInfoDialog = ({ show = false, onHide = {} }) => {

    const { user } = useAuth()

    function onHideCalled() {
        Api.updateUser(user.uid, { patternInfoDisplayed: true })
        onHide()
    }

    return (
        <Modal show={show} size="lg" centered onHide={onHideCalled}>
            <Modal.Header className="p-4" closeButton={true}>
                <h4 className="m-0">Sobre os padrões</h4>
            </Modal.Header>
            <Modal.Body className="px-4">
                <h6 className="fw-bold">Como funciona?</h6>
                <p className="m-0">Um padrão é composto por uma ou mais regras, onde cada regra deve ter um número gatilho que "puxa" outros números!</p>

                <h6 className="fw-bold mt-4">O que são regras?</h6>
                <p className="m-0 mb-1">As regas seguem o formato: <i className="text-dark">[Número Gatilho] → [Lista de Alvos]</i>.</p>
                <p className="m-0 mb-1">Isso significa que, se o gatilho aparecer na roleta, verificamos se o próximo número está na lista de alvos. Se todas as regras forem seguidas, enviamos uma notificação!</p>
                <p className="m-0">Você pode criar até 1 regra para cada número da roleta.</p>

                <h6 className="fw-bold mt-4">Quando notificar</h6>
                <p className="m-0 mb-1">Você pode definir quantas regras precisam ser cumpridas para que a notificação seja enviada.</p>
                <p className="m-0"><i><span className="text-muted">Ex: </span>Se você definir o "Quando notificar" como "Após 2", a notificação será enviada somente quando duas regras forem atendidas de forma sequencial.</i></p>
            
                <h6 className="fw-bold mt-4">Tolerância</h6>
                <p className="m-0 mb-1">Você pode definir até quantas rodadas seguintes podem ser consideradas para validar a regra.</p>
                <p className="m-0"><i><span className="text-muted">Ex: </span>Se você definir como "2 rodadas", o sistema analisará até duas rodadas para validar a regra.</i></p>
            </Modal.Body>
            <Modal.Footer className="px-4">
                <button className="btn btn-dark" onClick={onHideCalled}>Fechar</button>
            </Modal.Footer>
        </Modal>
    )
}
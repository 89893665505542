import { Group, NotificationsPaused, QueryStatsRounded, Repeat } from "@mui/icons-material"
import { useEffect, useState } from "react"
import { Card, Col, Row, Spinner } from "react-bootstrap"
import { Link } from "react-router-dom"
import { State } from "../../../components/State"
import { useAuth } from "../../../contexts/AuthContext"
import { Api } from "../../../service/Api"
import { FollowedEvent } from "./FollowedEvent"
import { mapEventLocked } from "./EventMap"
import AlertInformation from "../../../components/AlertInformation"

export const EventsHome = () => {

    const { user } = useAuth()
    const [events, setEvents] = useState({ loading: true, error: false, data: [] })
    const [trendingEvents, setTrendingEvents] = useState([])
    const [followedEvents, setFollowedEvents] = useState([])

    const isLoading = events.loading
    const isError = events.error

    useEffect(_ => {
        Api.fetchEvents().then(data => {
            setEvents({ loading: false, error: false, data })

            const events = data.map(event => {
                return event.variants.map(variant => ({ ...event, variant }))
            }).flat()

            events.sort((a, b) => b.variant.following - a.variant.following)
            setTrendingEvents(events.slice(0, 3))

            const userEventsVariants = user?.subscribedEvents?.map(event => event.variantId) || []
            const allEvents = events.filter(event => userEventsVariants.includes(event.variant.id))
            const userEvents = allEvents.map(event => ({ ...event, ...mapEventLocked(event, user.plan.type) }))
            setFollowedEvents(userEvents)
        })
    }, [user.subscribedEvents, user.plan.type])

    return (
        <>
            <State visible={!user.chatId}>
                <AlertInformation
                    icon={<NotificationsPaused />}
                    title="Telegram não conectado"
                    subtitle="Faça a conexão com Telegram para receber os alertas em tempo real."
                    linkText="Conectar com Telegram agora"
                    link="/dashboard/telegram"
                    variant="danger"
                    className="mb-4" />
            </State>

            <div className="mb-3">
                <h5>Eventos em alta 🔥</h5>
            </div>

            <State visible={isLoading}>
                <Card style={{ height: 120 }} className="d-flex align-items-center justify-content-center mb-3">
                    <Spinner />
                </Card>
            </State>

            <State visible={!isLoading && !isError && trendingEvents.length > 0}>
                <Row className="g-2">
                    {trendingEvents.map(t => (
                        <Col xs={12} md={4} key={t.key}>
                            <Card className="h-100 d-flex justify-content-between py-3 px-3">
                                <h6 className="m-0 text-dark">{t.name}</h6>
                                <div className="d-flex align-items-center mt-2 gap-3">
                                    <div className="d-flex align-items-center gap-1"><Repeat style={{ fontSize: 20 }} /> {t.variant.count}</div>
                                    <div className="d-flex align-items-center gap-1"><Group style={{ fontSize: 20 }} /> {t.variant.following}</div>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </State>

            <div className="d-flex justify-content-between align-items-center mt-5 mb-3">
                <h5>Eventos que você acompanha</h5>
                <Link to={'/dashboard/events/follow'} className="btn-link" state={events.data}>Alterar</Link>
            </div>

            <State visible={isLoading}>
                <Card style={{ height: 120 }} className="d-flex align-items-center justify-content-center mb-3">
                    <Spinner />
                </Card>
            </State>

            <State visible={!isLoading && !isError && followedEvents.length === 0}>
                <Card className="d-flex align-items-center gap-2 pt-4">
                    <QueryStatsRounded className="text-secondary" />
                    <h5 className="m-0">Você ainda não acompanha nenhum evento.</h5>
                    <p className="m-0 text-secondary">Selecione seu primeiro evento para acanpanhar e não perder oportunidades.</p>
                    <Link to={'/dashboard/events/follow'} className="btn-link" state={events.data}>Ver eventos</Link>
                </Card>
            </State>

            <State visible={!isLoading && !isError && followedEvents.length > 0}>
                {followedEvents.map(event => (
                    <FollowedEvent className="mb-2" key={event.id} event={event} locked={true} lockedTooltip="PLUS" />
                ))}
            </State>
        </>
    )
}
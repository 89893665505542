import { LoaderButton } from "../../../components/LoaderButton"
import { State } from "../../../components/State"
import { useAuth } from "../../../contexts/AuthContext"
import { usePlans } from "../../../contexts/PlansContext"
import { PlanConfig } from "./ActivePlanConfig"

export const ActivePlan = ({ onClickCancel }) => {

    const { user } = useAuth()
    const { plans } = usePlans()

    const userPlan = plans.find(el => el.type === user.plan.type)
    const planConfig = PlanConfig.find(el => el.type === user.plan.type)

    if (!planConfig) return <></>

    return (
        <div className={`rounded bg-white p-4 ${planConfig.borderClass}`}>
            <div>
                <h6 className='d-flex align-items-center gap-2 mb-4 text-uppercase fw-bold'>
                    {planConfig.icon}
                    {planConfig.title}
                </h6>
                <p className='text-secondary'>{planConfig.subtitle}</p>
                <ul>
                    {userPlan.benefits.map((benefit, index) => {
                        return (
                            <li key={`plan-benefit-${index}`}>{benefit}</li>
                        )
                    })}
                </ul>
            </div>

            <State visible={user.plan.type !== 'FREE' && user.plan.status === 'paused'}>
                <div className="alert alert-danger">
                    <h6 className="mb-1">Sua assinatura encontra-se pausada por falta de pagamento.</h6>
                    <p className="m-0">Acesse sua conta do Mercado Pago para reativar sua assinatura ou cancele para criar uma nova assinatura.</p>
                </div>
            </State>

            <div className='mt-4'>
                <LoaderButton className='mb-2 btn btn-outline-danger' onClick={onClickCancel}>Cancelar Assinatura</LoaderButton>
            </div>
        </div>
    )
}
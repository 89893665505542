import { Container } from "react-bootstrap"

export const Testimonials = () => {
    return (
        <section className="py-5 bg-white">
            <Container>
                <h4 className="text-center mb-5">O que nossos usuário dizem</h4>
                <div className="d-flex flex-column flex-md-row gap-4 align-items-center justify-content-md-between">
                    <blockquote className="text-center elevate-hover" style={{ maxWidth: 340 }}>
                        <img width={80} height={80} className="rounded-circle mb-4" src="./images/testimonial_profile1.png" alt="Avatar - Victor F." />
                        <p className="m-0 mb-2">“Curti, é bem simples e prático, faz tempo que procurava por algo assim”</p>
                        <footer className="fw-bold">— João R.</footer>
                    </blockquote>
                    <blockquote className="text-center elevate-hover" style={{ maxWidth: 340 }}>
                        <img width={80} height={80} className="rounded-circle mb-4" src="./images/testimonial_profile2.png" alt="Avatar - Victor F." />
                        <p className="m-0 mb-2">“Me ajuda a jogar no momento certo, agora consigo ter melhores resultados na roleta”</p>
                        <footer className="fw-bold">— Marcelo S.</footer>
                    </blockquote>
                    <blockquote className="text-center elevate-hover" style={{ maxWidth: 340 }}>
                        <img width={80} height={80} className="rounded-circle mb-4" src="./images/testimonial_profile3.png" alt="Avatar - Victor F." />
                        <p className="m-0 mb-2">Muito legal! Agora eu jogo somente quando meus padrões estão batendo”</p>
                        <footer className="fw-bold">— Ana B.</footer>
                    </blockquote>
                </div>
            </Container>
        </section>
    )
}
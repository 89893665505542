import WelcomeModal from "../welcome/WelcomeModal"
import AlertInformation from "../../../components/AlertInformation"
import { useNavigate } from "react-router-dom"
import { AlertsSection } from "./components/AlertsSection"
import { PatternsSection } from "./components/PatternsSection"
import { StaterSteps } from "./components/StarterSteps"
import { PreferenceAlert } from "./components/PreferenceAlert"
import { useEffect, useState } from "react"
import { useAuth } from "../../../contexts/AuthContext"
import { logEvent } from "firebase/analytics"
import { analytics } from "../../../firebase"
import { usePlans } from "../../../contexts/PlansContext"
import { useDashboard } from "../../../contexts/DashboardContext"
import { State } from "../../../components/State"
import { CreditCardOff, NotificationsPaused } from "@mui/icons-material"

export const DashboardHome = () => {

    const { user } = useAuth()
    const { plans } = usePlans()
    const [welcomeVisible, setWelcomeVisible] = useState(!user?.welcomeDisplayed)
    const navigate = useNavigate()
    const dashboard = useDashboard()

    const userPlan = plans.find(el => el.type === user.plan.type)
    const userDailyLimitReached = dashboard.todayAlerts.length >= (userPlan?.dailyAlerts + userPlan?.dailyEvents)

    useEffect(_ => {
        logEvent(analytics, 'page_view', { page: 'Dash-Home' })
    }, [])

    function goCreatePattern() {
        navigate('/dashboard/patterns/new')
    }

    function hideWelcome() {
        setWelcomeVisible(false)
    }

    return (
        <>

            <State visible={user.plan.type !== 'FREE' && user.plan.status === 'paused'}>
                <AlertInformation
                    icon={<CreditCardOff />}
                    title="Assinatura pausada"
                    variant="danger"
                    className="mb-3">
                    <div>
                        <p className="mb-0">Não conseguimos renovar sua assinatura através do Mercado Pago.</p>
                        <p className="mb-0">Os alertas enviados serão limitados aos limites da <b>versão FREE</b> até que seja feita a renovação do seu plano.</p>
                    </div>
                </AlertInformation>
            </State>

            <State visible={userDailyLimitReached}>
                <AlertInformation
                    icon={<NotificationsPaused />}
                    title="Notificações pausadas temporariamente"
                    linkText="Saiba mais sobre nossos planos"
                    link="/dashboard/plans"
                    variant="danger"
                    className="mb-3">
                    <div>
                        <p className="mb-0">Você atingiu seu limite diário dos alertas no Telegram</p>
                        <p className="mb-0">Enviaremos novos alertas amanhã, ou faça um upgrade de plano para receber novos alertas hoje</p>
                    </div>
                </AlertInformation>
            </State>

            <PreferenceAlert />
            <StaterSteps />
            <PatternsSection onClickCreatePattern={goCreatePattern} />
            <AlertsSection onClickCreatePattern={goCreatePattern} />
            <WelcomeModal show={welcomeVisible} onHide={hideWelcome} />
        </>
    )
}
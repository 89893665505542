import { Container } from "react-bootstrap"

export const Faq = () => {
    return (
        <section className="py-5 bg-white border-top">
            <Container>
                <h4 className="mb-5">Perguntas Frequentes</h4>
                <h6 className="fw-bold">Como configuro um padrão?</h6>
                <p className="mb-4">É simples, basta acessar a seção de meus padrões, definir seus critérios e salvar.</p>
                <h6 className="fw-bold">Como funciona a integração com o Telegram?</h6>
                <p className="mb-4">Conecte sua conta do Telegram no painel de configurações para receber notificações.</p>
                <h6 className="fw-bold">Quais roletas são monitoradas?</h6>
                <p className="m-0">Monitoramos as roletas mais famosas dos provedores Evolution, Ezugi, Playtech e Pragmatic.</p>
            </Container>
        </section>
    )
}
import { useLocation, useNavigate } from "react-router-dom"
import { FollowEventForm } from "./FollowEventForm"
import { useAuth } from "../../../contexts/AuthContext"
import { useState } from "react"
import { mapEventLocked, mapUpdateVariant, mapVariantsChecked, sortEventLevel } from "./EventMap"
import { Button } from "react-bootstrap"
import { LoaderButton } from "../../../components/LoaderButton"
import { Api } from "../../../service/Api"
import { useToast } from "../../../contexts/ToastContext"

export const EventsFollow = () => {

    const { user } = useAuth()
    const toast = useToast()
    const navigate = useNavigate()

    const location = useLocation()
    const events = location.state || []

    const [eventsForm, setEventsForm] = useState({
        changed: false,
        loading: false,
        events: events.map(event => mapEventLocked(event, user.plan.type))
            .map(event => mapVariantsChecked(event, user.subscribedEvents))
            .sort(sortEventLevel)
    })

    const onChangeEventVariant = (variant, checked) => {
        setEventsForm(state => ({
            changed: true,
            events: state.events.map(event => mapUpdateVariant(event, variant, checked))
        }))
    }

    const handleConfirmClick = () => {
        if (!eventsForm.changed) {
            return navigate(-1)
        } else {
            const selectedVariants = eventsForm.events.map(e => e.variants).flat().filter(v => v.checked)
            const selectedEvents = eventsForm.events.filter(event => event.variants.some(v => v.checked))
            const subscribedEvents = selectedVariants.map(variant => {
                const eventId = selectedEvents.find(e => e.variants.some(v => v.id === variant.id)).id
                return { eventId, variantId: variant.id }
            })

            setEventsForm(state => ({ ...state, loading: true }))
            Api.updateUserSubscribedEvents(user.uid, subscribedEvents)
                .then(_ => navigate(-1))
                .catch(_ => toast.error('Desculpe, ocorreu um erro!'))
                .finally(_ => setEventsForm(state => ({ ...state, loading: false })))
        }
    }

    return (
        <>
            <h5 className="mb-4">Marque os eventos que deseja acompanhar</h5>
            {eventsForm.events.map(event => (
                <FollowEventForm key={event.id} event={event} className="mb-2" onChange={onChangeEventVariant} />
            ))}
            <div className="mt-3 d-flex justify-content-end gap-2">
                <Button variant="dark" onClick={_ => navigate(-1)}>Cancelar</Button>
                <LoaderButton
                    className="btn btn-primary"
                    style={{ minWidth: 120 }}
                    onClick={handleConfirmClick}
                    loading={eventsForm.loading}>Salvar</LoaderButton>
            </div>
        </>
    )
}